import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {EventsTab} from '@hconnect/common/types'
import {Column, PaginationOptions} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import {isEqual} from 'lodash'
import React, {FC} from 'react'

import {
  AllFilterOptions,
  FilterOptions,
  pmNotificationFilterOptions,
  PmNotificationFilterOptionsKeys
} from '../../common/utils/filterParameter'
import {PmNotificationStatusLabel} from '../../components/eventProperties/PmNotificationStatusLabel'
import {
  generateWorkOrderEquipmentNumberColumn,
  getTitleColumn,
  Label,
  Secondary
} from '../../components/tableHelpers'
import {useConfig} from '../../hooks/useConfig'
import {SortOrder, WorkOrderSortBy} from '../../types/shiftHandover.types'
import {ColumnsMap} from '../../types/table.types'
import {Notification} from '../../types/workOrders.types'
import {NotificationSideCard} from '../worksOrders/NotificationSideCard'

import {EventsListView} from './EventsListView'

const generateColumnsMap = (
  t: TFunction,
  timezone: string,
  freeSearchText?: string
): ColumnsMap<Notification> => {
  const idColumn: Column<Notification> = {
    key: 'id',
    label: <Label>{t('shiftEvent.label.NotificationId')}</Label>,
    customTemplate: ({id}) => <Secondary>{id}</Secondary>
  }
  const createdAtColumn: Column<Notification> = {
    key: 'createdAt',
    label: <Label>{t('shiftEvent.label.createdAt')}</Label>,
    customTemplate: ({createdAt}) => (
      <Secondary sx={{whiteSpace: 'nowrap'}}>
        <DateTimeFormat date={createdAt} timezone={timezone} />
      </Secondary>
    )
  }
  const generateStatusColumn = (type?: 'small' | 'regular'): Column<Notification> => ({
    headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
    key: 'status',
    sortable: true,
    width: type === 'small' ? 0 : undefined,
    label: <Label>{t('shiftEvent.label.status')}</Label>,
    customTemplate: (item: Notification) => (
      <Secondary>
        <PmNotificationStatusLabel status={item.status} iconOnly={type === 'small'} />
      </Secondary>
    )
  })

  return {
    XS: [idColumn, getTitleColumn(t, freeSearchText), createdAtColumn],
    S: [idColumn, getTitleColumn(t, freeSearchText), createdAtColumn],
    M: [
      idColumn,
      getTitleColumn(t, freeSearchText),
      createdAtColumn,
      generateWorkOrderEquipmentNumberColumn('small', t, freeSearchText),
      generateStatusColumn('regular')
    ],
    L: [
      idColumn,
      getTitleColumn(t, freeSearchText),
      createdAtColumn,
      generateWorkOrderEquipmentNumberColumn('regular', t, freeSearchText),
      generateStatusColumn('regular')
    ],
    XL: [
      idColumn,
      getTitleColumn(t, freeSearchText),
      createdAtColumn,
      generateWorkOrderEquipmentNumberColumn('regular', t, freeSearchText),
      generateStatusColumn('regular')
    ]
  }
}

type NotificationsListViewProps = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
  activeFilter: AllFilterOptions
  events?: Notification[]
  isLoading?: boolean
  showBottomProgress?: boolean
  onFilterChange: (next: FilterOptions) => void
  paginationOptions?: PaginationOptions
}

export const NotificationsListView: FC<NotificationsListViewProps> = (props) => {
  const {timezone} = useConfig()
  const onSort = (sortBy: string, sortOrder?: SortOrder) => {
    const next = {
      ...props.activeFilter,
      sortNotificationsBy: sortBy as WorkOrderSortBy,
      notifSortDir: sortOrder
    }
    if (!sortBy || !sortOrder || isEqual(props.activeFilter, next)) {
      return
    }
    props.onFilterChange(next)
  }

  return (
    <EventsListView<Notification, PmNotificationFilterOptionsKeys, WorkOrderSortBy>
      {...props}
      urlParamId="notificationId"
      generateColumnsMap={(t) => generateColumnsMap(t, timezone, props.activeFilter.freeText)}
      SideCardComponent={NotificationSideCard}
      filterOptions={pmNotificationFilterOptions}
      sortedBy={props.activeFilter.sortNotificationsBy}
      sortDirection={props.activeFilter.notifSortDir}
      onSort={onSort}
    />
  )
}
