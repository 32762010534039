import {
  categories,
  Category,
  EquipmentData,
  EventType,
  eventTypes,
  Priority,
  TaskRepetitionUnit,
  taskRepetitionUnits,
  ParticularShift,
  Shift,
  DateRange,
  MaintenanceNotification,
  EquipmentIdType,
  equipmentIdTypes
} from '@hconnect/common/types'
import {getShift} from '@hconnect/common/utils'
import {isString, isUndefined, omitBy, pick} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {
  PmNotificationStatus,
  pmNotificationStatusListApprovalStates,
  SortBy,
  SortOrder,
  statusListWithApproverStates,
  StatusWithNoneAndCancellationAndApproverStates,
  WorkOrderStatus,
  workOrderStatusListApprovalStates,
  WorkOrderSortBy
} from '../../types/shiftHandover.types'

import {
  alignToShiftEnd,
  alignToShiftStart,
  getShiftBounds,
  getShiftsForDay,
  getTimeRangeForShift,
  sortDateShiftsOnStartTime,
  isTimeEqual
} from './shift'

export const QUICK_SELECT_SLOTS = [
  'currentShift',
  'pastShift',
  'last24hours',
  'yesterday',
  'lastWeekend',
  'last3days',
  'last7days',
  'last30days',
  'next30days'
] as const

export type QuickSelectSlots = (typeof QUICK_SELECT_SLOTS)[number]

export type PmNotificationFilterOptions = {
  equipment?: EquipmentData
  pmNotificationStatus?: PmNotificationStatus[]
  freeText?: string
  sortNotificationsBy?: WorkOrderSortBy
  notifSortDir?: SortOrder
}
export type WorkOrdersFilterOptions = {
  equipment?: EquipmentData
  workOrderStatus?: WorkOrderStatus[]
  freeText?: string
  sortWorkOrderBy?: WorkOrderSortBy
  woSortDir?: SortOrder
}

export type FilterOptions = {
  category?: Category[]
  timeRange?: DateRange | QuickSelectSlots
  eventType?: EventType[]
  processStage?: string[]
  status?: StatusWithNoneAndCancellationAndApproverStates[]
  priority?: Priority[]
  equipment?: EquipmentData
  mainEquipment?: EquipmentData
  repetition?: TaskRepetitionUnit[]
  stoppageCode?: string[]
  freeText?: string
  sortBy?: SortBy
  sortOrder?: SortOrder
  maintenanceNotificationExists?: MaintenanceNotification[]
}

export type CombinedWorkOrdersFilterOptions = PmNotificationFilterOptions & WorkOrdersFilterOptions
export type AllFilterOptions = FilterOptions & CombinedWorkOrdersFilterOptions

export type DesktopFilterOptionsKeys = keyof Omit<FilterOptions, 'timeRange'>
export type AllDesktopFilterOptionsKeys = keyof Omit<AllFilterOptions, 'timeRange'>

export type RecurrenceTasksFilterOptionsKeys = keyof Pick<
  FilterOptions,
  | 'repetition'
  | 'equipment'
  | 'mainEquipment'
  | 'processStage'
  | 'category'
  | 'sortBy'
  | 'sortOrder'
>
export type PmNotificationFilterOptionsKeys = keyof Pick<
  PmNotificationFilterOptions,
  'equipment' | 'freeText' | 'pmNotificationStatus'
>
export type WorkOrdersFilterOptionsKeys = keyof Pick<
  WorkOrdersFilterOptions,
  'equipment' | 'freeText' | 'workOrderStatus'
>
export type DesktopTasksFilterOptionsKeys = keyof Pick<
  FilterOptions,
  'priority' | 'equipment' | 'mainEquipment' | 'processStage' | 'category'
>

export type DesktopShiftSummaryFilterOptionsKeys = keyof Omit<
  FilterOptions,
  'repetition' | 'timeRange'
>

export const defaultDesktop = ['timeRange']

// Quick fix for equipment sap numbers with '_' in id and text fields
const transformEquipmentString = (str: string) => str.replaceAll('_', '%%')
const transformBackEquipmentString = (str: string) => str.replaceAll('%%', '_')

export const desktopFilterOptions: DesktopFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'eventType',
  'category',
  'priority',
  'status',
  'stoppageCode',
  'freeText',
  'maintenanceNotificationExists'
]

export const pmNotificationFilterOptions: PmNotificationFilterOptionsKeys[] = [
  'equipment',
  'freeText',
  'pmNotificationStatus'
]
export const workOrderFilterOptions: WorkOrdersFilterOptionsKeys[] = [
  'equipment',
  'freeText',
  'workOrderStatus'
]

export const recurrenceTasksFilterOptions: RecurrenceTasksFilterOptionsKeys[] = [
  'repetition',
  'equipment',
  'mainEquipment',
  'processStage',
  'category'
]

export const desktopTasksFilterOptions: DesktopTasksFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'category',
  'priority'
]

export const desktopShiftSummaryFilterOptions: DesktopShiftSummaryFilterOptionsKeys[] = [
  'processStage',
  'equipment',
  'mainEquipment',
  'eventType',
  'category',
  'priority',
  'status',
  'stoppageCode'
]

export const sortingFilterParams: DesktopFilterOptionsKeys[] = ['sortBy', 'sortOrder']
export const sortingWorkOrdersFilterParams: AllDesktopFilterOptionsKeys[] = [
  'woSortDir',
  'sortWorkOrderBy'
]
export const sortingNotificationsFilterParams: AllDesktopFilterOptionsKeys[] = [
  'notifSortDir',
  'sortNotificationsBy'
]

// using a short format for url inclusion instead of full ISO8601
const dateFormat = 'YYYYMMDDHHmmssSSS'

export const getTimeRangeString = (startDate, endDate) =>
  `${startDate.utc().format(dateFormat)},${endDate.utc().format(dateFormat)}`

const equipmentToParameter = (equipment: EquipmentData): string => {
  if (!equipment.id || !equipment.idType || !equipment.text)
    throw new Error('incomplete equipment parameter')
  return `${equipment.idType}_${transformEquipmentString(equipment.id)}_${transformEquipmentString(
    equipment.text
  )}`
}

const parameterToEquipment = (parameter?: string): EquipmentData | undefined => {
  if (!parameter) return

  const input = parameter.split('_')
  const idType = input[0] as EquipmentIdType
  const id = transformBackEquipmentString(input[1])
  const text = transformBackEquipmentString(input[2])

  if (input.length === 3 && equipmentIdTypes.includes(idType) && id && text) {
    return {
      idType,
      id,
      text
    }
  } else {
    throw new Error('invalid equipment parameter')
  }
}

export const filterSettingsToUrl = (settings: FilterOptions): string => {
  return filterSettingsToUrlParams(settings).toString()
}

export const filterSettingsToUrlParams = (settings: FilterOptions): URLSearchParams => {
  const params = new URLSearchParams()

  // time ranges handled explicitly
  if (settings.timeRange) {
    if (isString(settings.timeRange)) {
      params.append('timeRange', settings.timeRange)
    } else {
      const {startDate, endDate} = settings.timeRange
      if (!startDate || !endDate)
        throw new Error('if either of startDate or endDate is given, both must be set')
      params.append('timeRange', getTimeRangeString(startDate, endDate))
    }
  }
  if (settings.mainEquipment) {
    params.append('mainEquipment', equipmentToParameter(settings.mainEquipment))
  }
  if (settings.equipment) {
    params.append('equipment', equipmentToParameter(settings.equipment))
  }

  // all other properties are strings and can simply be copied over
  const ignoredKeys: (keyof FilterOptions)[] = ['timeRange', 'equipment', 'mainEquipment']
  let key: keyof FilterOptions
  for (key in settings) {
    // time ranges handled explicitly and should be skipped
    if (ignoredKeys.includes(key)) continue
    const value = settings[key]
    // TODO: @cockpit add type guard for variable 'value' below
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if (value && !Array.isArray(value)) params.append(key, value.toString())
    if (value && Array.isArray(value) && value.length > 0) {
      if (value.length === 1) params.append(key, value[0].toString())
      else params.append(key, value.map((v) => v.toString()).join(','))
    }
  }

  return params
}

export function getParam(params: URLSearchParams, key: string) {
  return params.get(key) ?? undefined
}

const isNumeric = (num: unknown) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number)

function getArrayParam<T extends string | number = string>(
  params: URLSearchParams,
  key: string,
  parse: 'number' | 'string' = 'string'
) {
  const p = params.get(key) ?? undefined
  if (!p) return undefined
  const splitted = p.split(',')
  if (splitted.length > 0) {
    if (parse === 'number' && splitted.every((split) => isNumeric(split))) {
      return splitted.map((str) => parseInt(str, 10)) as T[]
    }
    return splitted as T[]
  } else return undefined
}

export const generateTimeRangeFromUrlParam = (timeRange: string) => {
  let result
  if (QUICK_SELECT_SLOTS.includes(timeRange as QuickSelectSlots)) {
    result = timeRange as QuickSelectSlots
  } else {
    const [startDate, endDate] = timeRange.split(',')
    result = {
      startDate: moment.utc(startDate, dateFormat),
      endDate: moment.utc(endDate, dateFormat)
    }
  }
  return result
}

// eslint-disable-next-line complexity
export const urlToFilterSettings = (
  params: URLSearchParams,
  warn: (text: string) => void = console.warn
): FilterOptions => {
  const result: AllFilterOptions = {}

  const timeRange = getParam(params, 'timeRange')
  if (timeRange) {
    result.timeRange = generateTimeRangeFromUrlParam(timeRange)
  }

  const processStage = getArrayParam(params, 'processStage')
  if (processStage) result.processStage = processStage

  const equipment = parameterToEquipment(getParam(params, 'equipment'))
  if (equipment) result.equipment = equipment

  const mainEquipment = parameterToEquipment(getParam(params, 'mainEquipment'))
  if (mainEquipment) result.mainEquipment = mainEquipment

  const eventType = getArrayParam<EventType>(params, 'eventType')
  if (eventType) {
    const valid = validateArrayValues<EventType>(eventType, [...eventTypes], warn, 'eventType')
    if (valid) result.eventType = eventType
  }

  const priority = getArrayParam<Priority>(params, 'priority', 'number')
  if (priority) {
    const valid = priority.every((val) => isNumeric(val))
    if (valid) result.priority = priority
    else warn(`${priority} is not a valid value for priority`)
  }

  const status = getArrayParam<StatusWithNoneAndCancellationAndApproverStates>(params, 'status')
  if (status) {
    const valid = validateArrayValues<StatusWithNoneAndCancellationAndApproverStates>(
      status,
      [...statusListWithApproverStates],
      warn,
      'status'
    )
    if (valid) result.status = status
  }

  const pmNotificationStatus = getArrayParam<PmNotificationStatus>(params, 'pmNotificationStatus')
  if (pmNotificationStatus) {
    const valid = validateArrayValues<PmNotificationStatus>(
      pmNotificationStatus,
      pmNotificationStatusListApprovalStates,
      warn,
      'pmNotificationStatus'
    )
    if (valid) result.pmNotificationStatus = pmNotificationStatus
  }

  const workOrderStatus = getArrayParam<WorkOrderStatus>(params, 'workOrderStatus')
  if (workOrderStatus) {
    const valid = validateArrayValues<WorkOrderStatus>(
      workOrderStatus,
      workOrderStatusListApprovalStates,
      warn,
      'workOrderStatus'
    )
    if (valid) result.workOrderStatus = workOrderStatus
  }

  const category = getArrayParam<Category>(params, 'category')
  if (category) {
    const valid = validateArrayValues<Category>(category, [...categories], warn, 'category')
    if (valid) result.category = category
  }

  const recurrence = getArrayParam<TaskRepetitionUnit>(params, 'repetition')
  if (recurrence) {
    const valid = validateArrayValues<TaskRepetitionUnit>(
      recurrence,
      [...taskRepetitionUnits],
      warn,
      'repetition'
    )
    if (valid) result.repetition = recurrence
  }

  result.stoppageCode = getArrayParam<string>(params, 'stoppageCode')

  result.freeText = getParam(params, 'freeText')

  result.sortBy = getParam(params, 'sortBy') as SortBy
  result.sortWorkOrderBy = getParam(params, 'sortWorkOrderBy') as WorkOrderSortBy
  result.sortNotificationsBy = getParam(params, 'sortNotificationsBy') as WorkOrderSortBy

  result.sortOrder = getParam(params, 'sortOrder') as SortOrder
  result.woSortDir = getParam(params, 'woSortDir') as SortOrder
  result.notifSortDir = getParam(params, 'notifSortDir') as SortOrder
  result.maintenanceNotificationExists = getArrayParam<string>(
    params,
    'maintenanceNotificationExists'
  ) as MaintenanceNotification[]

  return result
}

function getCurrentShiftTiming(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts, timezone)
  const endDate = moment.utc(currentShift.utcEndDate)
  return [moment.utc(currentShift.utcStartDate), moment.utc(endDate)]
}

function getPastShiftTiming(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts, timezone)
  if (!currentShift) throw new Error('unable to find current shift')

  const currentShiftStartTime = moment.utc(currentShift.utcStartDate).add(1, 'second')

  const todayShifts: ParticularShift[] = sortDateShiftsOnStartTime(
    currentShiftStartTime,
    shifts,
    timezone
  )
  let pastShift: Shift | null = null

  // Alternative: instead check if current time is between todayShifts[0] startDate and endDate
  if (
    isTimeEqual(todayShifts[0].utcStartDate, currentShift.utcStartDate) &&
    isTimeEqual(todayShifts[0].utcEndDate, currentShift.utcEndDate)
  ) {
    // desired past shift for the first shift of the day is the last shift of yesterday
    const yesterday = currentShiftStartTime.clone().subtract(1, 'day')
    const yesterdayShifts = sortDateShiftsOnStartTime(yesterday, shifts, timezone)
    pastShift = yesterdayShifts[yesterdayShifts.length - 1]
  } else {
    for (let i = 1; i < todayShifts.length; i++) {
      const {utcStartDate, utcEndDate} = todayShifts[i]
      const startCheck = isTimeEqual(utcStartDate, currentShift.utcStartDate),
        endCheck = isTimeEqual(utcEndDate, currentShift.utcEndDate)
      if (startCheck && endCheck) {
        pastShift = todayShifts[i - 1]
        break
      }
    }
  }

  const {startDate, endDate} = getTimeRangeForShift(
    currentShiftStartTime.toDate(),
    pastShift as Shift,
    timezone
  )
  return [startDate, endDate]
}

function getLast24hoursTiming(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const currentShift = getShift(now, shifts, timezone)
  const endDate = moment.utc(currentShift.utcEndDate)
  return [endDate.clone().subtract(24, 'hours'), endDate]
}

function getYesterdayShiftTiming(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const yesterday = now.clone().subtract(1, 'day')
  const yesterdayDate = yesterday.toDate()
  const yesterdaysShifts = getShiftsForDay(yesterday, shifts, timezone)
  const sortedShifts = yesterdaysShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
  const start = alignToShiftStart(yesterdayDate, sortedShifts[0], timezone)
  const end = alignToShiftEnd(yesterdayDate, sortedShifts[sortedShifts.length - 1], timezone)
  return [moment(start), moment(end)]
}

function getLastWeekendShiftTimings(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const sun: Moment = now.clone().subtract(now.day(), 'day')
  const fri: Moment = sun.clone().subtract(2, 'day')
  const sunShifts = getShiftsForDay(sun, shifts, timezone)
  const sortedSunShifts = sunShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
  const friShifts = getShiftsForDay(fri, shifts, timezone)
  const sortedFriShifts = friShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
  const start = alignToShiftStart(
    fri.toDate(),
    sortedFriShifts[sortedFriShifts.length - 1],
    timezone
  )
  const end = alignToShiftEnd(sun.toDate(), sortedSunShifts[sortedSunShifts.length - 1], timezone)
  return [moment(start), moment(end)]
}

function getLast3days(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const last3rdDay: Moment = now.clone().subtract(3, 'day')
  return getShiftBounds(shifts, last3rdDay, now, timezone, true)
}

function getLast7days(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const last7thDay: Moment = now.clone().subtract(7, 'day')
  return getShiftBounds(shifts, last7thDay, now, timezone, true)
}

function getLast30days(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const last30thDay: Moment = now.clone().subtract(30, 'day')
  return getShiftBounds(shifts, last30thDay, now, timezone, true)
}

function getNext30days(
  now: Moment,
  shifts: Shift[],
  timezone: string
): [start: Moment, end: Moment] {
  const next30days: Moment = now.clone().add(30, 'day')
  return getShiftBounds(shifts, now, next30days, timezone)
}

export function getQuickSelectFilter(
  slot: QuickSelectSlots,
  shifts: Shift[],
  now: Moment,
  timezone: string
): [start: Moment, end: Moment] {
  let dates: [start: Moment, end: Moment]

  switch (slot) {
    case 'currentShift':
      dates = getCurrentShiftTiming(now, shifts, timezone)
      break
    case 'pastShift':
      dates = getPastShiftTiming(now, shifts, timezone)
      break
    case 'last24hours':
      dates = getLast24hoursTiming(now, shifts, timezone)
      break
    case 'yesterday':
      dates = getYesterdayShiftTiming(now, shifts, timezone)
      break
    case 'lastWeekend':
      dates = getLastWeekendShiftTimings(now, shifts, timezone)
      break
    case 'last3days':
      dates = getLast3days(now, shifts, timezone)
      break
    case 'last7days':
      dates = getLast7days(now, shifts, timezone)
      break
    case 'last30days':
      dates = getLast30days(now, shifts, timezone)
      break
    case 'next30days':
      dates = getNext30days(now, shifts, timezone)
      break
    default:
      throw new Error(`Unknown predefined time slot: ${slot}`)
  }
  return dates
}

function validateArrayValues<T extends string | number>(
  toValidate: T[],
  availableValues: T[],
  warn: (text: string) => void,
  key: string
): boolean {
  let valid = true
  toValidate.map((val) => {
    if (!availableValues.includes(val)) {
      valid = false
      warn(`${val} is not a valid value for ${key}`)
    }
  })
  return valid
}

export enum Page {
  Events = 'events',
  WorkOrders = 'workOrders',
  Notifications = 'notifications',
  Tasks = 'tasks',
  Summary = 'summary',
  Performance = 'performance'
}
export const filterPageKeys: Record<Page, AllDesktopFilterOptionsKeys[]> = {
  [Page.Events]: [...desktopFilterOptions, ...sortingFilterParams],
  [Page.WorkOrders]: [...workOrderFilterOptions, ...sortingWorkOrdersFilterParams],
  [Page.Notifications]: [...pmNotificationFilterOptions, ...sortingNotificationsFilterParams],
  [Page.Tasks]: desktopTasksFilterOptions,
  [Page.Summary]: desktopShiftSummaryFilterOptions,
  [Page.Performance]: []
}

export const getPageScopedFilters = (
  filters: string,
  page: Page,
  defaultFilters?: Partial<FilterOptions>
): FilterOptions => {
  const filterOptions: FilterOptions = urlToFilterSettings(new URLSearchParams(filters))
  return {
    ...defaultFilters,
    ...omitBy(pick(filterOptions, ['timeRange', ...filterPageKeys[page]]), isUndefined)
  }
}
